<template>
  <div class="custom_menu_box">
    <div class="custom_menu_left">
      <div class="custom_menu_title_box">
        <div></div>
        <div class="custom_menu_name">{{ title }}</div>
        <i class="el-icon-user"></i>
      </div>

      <div class="custom_menu_bottom_box">
        <i class="el-icon-caret-top"></i>
        <div class="tabs_box">
          <div
            class="tabs_item"
            v-for="(item, index) in tabArr"
            :key="index"
            @click="openSmall(index)"
          >
            <i
              v-if="index !== 0"
              class="el-icon-caret-left"
              style="font-size:10px;color:#969696"
              @click.stop="setMenu('left', index)"
            ></i>
            {{ item.name }}

            <div class="tabs_item_add_box" v-if="selectTab == index">
              <div
                class="tabs_item_add_item"
                :class="selectItem == i ? 'select_tabs_item_add_item' : ''"
                v-for="(elm, i) in 5"
                :key="elm"
                @click.stop="toSet('set', i)"
              >
                789
              </div>

              <div
                class="tabs_item_add_item"
                :class="selectItem == null ? 'select_tabs_item_add_item' : ''"
                @click.stop="toSet('add', null)"
              >
                <i
                  class="el-icon-plus"
                  style="font-size:20px;color:#969696"
                ></i>
              </div>
            </div>
            <i
              v-if="index !== 2"
              class="el-icon-caret-right"
              style="font-size:10px;color:#969696"
              @click.stop="setMenu('right', index)"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <!-- 右边 -->
    <div class="custom_menu_right">
      <div class="custom_menu_small_box">
        <div class="menu_small_titel">
          {{ smallTitle }}
        </div>
        <div class="remove_title">
          删除子菜单
        </div>
      </div>

      <div class="custom_menu_content">
        <div class="custom_menu_item_box">
          <div class="custom_menu_name">子菜单名称</div>
          <div class="input_box">
            <el-input
              style="width:400px"
              v-model="input"
              placeholder="请输入内容"
            ></el-input>
            <div style="color:#969696">
              仅支持中英文和数字，字教不超过8个汉字或16个字母
            </div>
          </div>
        </div>

        <div class="custom_menu_item_box">
          <div class="custom_menu_name">子菜单内容</div>
          <div class="input_box">
            <el-radio-group v-model="radio" @change="changeRadio">
              <el-radio :label="3" class="margin_right">发送消息</el-radio>
              <el-radio :label="6" class="margin_right">跳转网页</el-radio>
              <el-radio :label="9" class="margin_right">跳转小程序</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="jump_box">
          <div class="jump_box_title">订阅者点击该子菜单会跳到以下链接</div>
          <div class="jump_input_box">
            <div class="jump_input_title">页面地址</div>
            <el-input
              style="width:350px"
              v-model="input"
              placeholder="请输入页面地址"
            ></el-input>
          </div>
        </div>

        <div class="btn_box">
          <el-button type="primary">保存并发布</el-button>
          <el-button type="primary">保存并发布</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "公众号名称",
      tabArr: [{ name: "了解恰学" }, { name: "免费学" }, { name: "资讯报名" }],
      selectTab: -1,
      selectItem: -1,
      smallTitle: "恰学介绍",
      input: "",
      radio: ""
    };
  },
  methods: {
    openSmall(index) {
      console.log(index);
      this.selectTab = index;
    },
    toSet(target, index) {
      this.selectItem = index;
      console.log(target, index);
    },
    changeRadio(index) {
      console.log(index);
    },
    setMenu(target, index) {
      console.log(target, index);
      if (target == "left") {
        if (index < 1) return;
        this.tabArr[index] = this.tabArr.splice(
          index - 1,
          1,
          this.tabArr[index]
        )[0];
        return this.tabArr;
      } else {
        if (index >= this.tabArr.length - 1) return this.tabArr;
        this.tabArr[index] = this.tabArr.splice(
          index + 1,
          1,
          this.tabArr[index]
        )[0];
        return this.tabArr;
      }
    }
  }
};
</script>

<style scoped lang="less">
* {
  //   margin: 0;
  //   padding: 0;
  box-sizing: border-box;
}
.custom_menu_box {
  display: flex;

  .custom_menu_left {
    position: relative;
    width: 400px;
    height: 600px;
    border: 1px solid #ccc;
    .custom_menu_title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      background-color: #38393b;
      color: #fff;
      padding: 0 15px;
      .custom_menu_name {
        // color: 14px;
        font-weight: 800;
      }
      i {
        font-size: 20px;
      }
    }

    .custom_menu_bottom_box {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #fafafa;
      border-top: 1px solid #d3d3d3;
      cursor: pointer;
      i {
        font-size: 30px;
        width: 30px;
      }
      .tabs_box {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #323232;
        .tabs_item {
          width: 100%;
          height: 100%;
          line-height: 40px;
          border-left: 1px solid #d3d3d3;
          text-align: center;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: nowrap;
          .tabs_item_add_box {
            width: 100%;
            position: absolute;
            bottom: 50px;
            left: 0;
            // border: 1px solid #d3d3d3;
            .tabs_item_add_item {
              border: 1px solid #d3d3d3;
              border-bottom: 0;
              &:nth-last-child(1) {
                border-bottom: 1px solid #d3d3d3;
              }
            }
            .select_tabs_item_add_item {
              border: 1px solid #26c873;
              &:nth-last-child(1) {
                border-bottom: 1px solid #26c873;
              }
            }
          }
        }
      }
    }
  }

  .custom_menu_right {
    flex: 1;
    height: 600px;
    margin-left: 30px;
    background-color: #f4f5f9;
    padding: 0 15px;
    border: 1px solid #ddd;
    .custom_menu_small_box {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      height: 30px;
      align-items: center;

      .remove_title {
        color: #58719e;
      }
    }

    .custom_menu_content {
      margin-top: 30px;
      .custom_menu_item_box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        .custom_menu_name {
          width: 100px;
          //   margin-top: 10px;
        }
        .input_box {
          flex: 1;
          div {
            margin-top: 5px;
          }
          .margin_right {
            margin-right: 20px;
          }
        }
      }

      .jump_box {
        height: 120px;
        width: 100%;
        background-color: #fff;
        padding: 15px;
        .jump_box_title {
          color: #969696;
          margin-bottom: 10px;
        }
        .jump_input_box {
          display: flex;
          align-items: center;
          .jump_input_title {
            width: 100px;
          }
        }
      }

      .btn_box {
        margin-top: 100px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
